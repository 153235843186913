import React, { Fragment, useState, useRef, useEffect, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import CartLink from "../Cart/CartLink"
import NewCartLink from "../Cart/NewCartLink"
import styled from "styled-components"
import ELink from "../Core/ExternalLink"
import InstagramLink from "../Core/InstagramLink"
import colors from "src/styles/colors"
import breakpoints from "src/styles/breakpoints"
import { useInView } from "react-intersection-observer"
import { setBodyOverflow } from "src/services/core/browser"
import { LinkWithPrevUrl } from "../Core/LinkWithPrevUrl"
import { useUserContext } from "../../context/UserContext"
import { CartContext } from "../../context/CartContext"

import MobileDrawer from "src/components/Core/Header/MobileDrawer"

function CartItemsCount() {
  const [cart] = useContext(CartContext)

  return cart && cart.item_quantity ? cart.item_quantity : 0
}

function sendInstagramGtag() {
  let gtag_data = {
    event_category: "engagement",
    event_label: "header_instagram",
  }
  // if (typeof window.gtag !== 'undefined') {
  //   window.gtag("event", "click", gtag_data)
  //   //todo: add GA4 event
  // }
}

function sendLoginGtag() {
  let gtag_data = {
    event_category: "engagement",
    event_label: "header_login",
  }
  // if (typeof window.gtag !== 'undefined') {
  //   window.gtag("event", "click", gtag_data)
  //   //todo: add GA4 event
  // }
}

function sendCartGtag() {
  let gtag_data = {
    event_category: "engagement",
    event_label: "header_cart",
  }
  // if (typeof window.gtag !== 'undefined') {
  //    window.gtag("event", "click", gtag_data)
  //    //todo: add GA4 event
  // }
}

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  padding-top: ${(props) => (props.scrolled ? "16px" : undefined)};
  padding-bottom: ${(props) => (props.scrolled ? "16px" : undefined)};
  color: ${(props) => (props.dark ? colors.black : colors.white)};
  background: ${(props) =>
    props.transparent
      ? "transparent"
      : props.bg 
      ? props.bg
      : colors.lightBlue};
  transition: padding 140ms ease-in-out, background-color 140ms ease-in-out;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 72px;
    padding-right: 72px;
    transition: padding 140ms ease-in-out, background-color 300ms ease-in-out;
  }
`

const HomeLink = styled(Link)`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2em;
  letter-spacing: 0.01em;
  text-decoration: none;
  color: inherit;

  @media (min-width: ${breakpoints.md}) {
    font-size: calc(0.24vw + 1rem);
  }
`

const Section = styled.div`
  display: ${(props) => (props.desktop ? "none" : "block")};
  @media (min-width: ${breakpoints.md}) {
    width: 33.3%;
    display: flex;
    justify-content: ${(props) =>
      props.left ? "flex-start" : props.right ? "flex-end" : "center"};
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-between;
  margin-right: 0;
`

const InternalLinkButton = styled(Link)`
display: ${(props) => (props.desktop ? "none" : "block")};
font-weight:700;
@media (min-width: ${breakpoints.md})
{
  display: block;
}
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));

  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 0.35em 1.2em;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  cursor: pointer;
  display: inline-block;
  background: #319ad5;
  border-color: #319ad5;
  color: white!important;
  font-family: "Work Sans";
  font-style: normal;
  font-weight:700;
  font-size: 1rem;
  line-height: 1.6em;
  ${'' /* font-size: 20px;
  line-height: 18px; */}

  @media (max-width: ${breakpoints.md}) {
    ${'' /* margin-bottom: 20px; */}
  }
`

const Button = styled.div`
  width: 50px;
  display: ${(props) => (props.desktop ? "none" : "flex")};
  align-items: center;
  justify-content: center;

  .account {
    font-size: 16px;
    line-height: 1.6;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    margin-left: 5vw;
    width: unset;
  }
`

const Burger = styled.button`
  height: 35px;
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 50px;

  .open {
    .line {
      background-color: ${(props) => (props.bg && props.bg == colors.white ? colors.royalBlue : "white")};
    }
    .dark {
      background-color: ${colors.black};
    }
    .burger-top {
      transform: translateY(-5.5px);
    }
    .burger-bottom {
      transform: translateY(5.5px);
    }
  }

  .close {
    .line {
      background-color: ${colors.black};
      width: 28px;
    }
    .burger-top {
      transform: translateX(3.5px) rotate(-135deg);
    }
    .burger-bottom {
      transform: translateX(3.5px) rotate(135deg);
    }
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Open = styled.div`
  width: 35px;
  height: 35px;
  margin: auto;
  position: relative;

  div {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    transition: transform 250ms cubic-bezier(0.2, 0.6, 0.3, 1),
      width 250ms cubic-bezier(0.2, 0.6, 0.3, 1);
  }
`
const LinksBlock = styled.nav`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
`

const SingleLink = styled.div`
display: ${(props) => (props.desktop ? "none" : "block")};
@media (min-width: ${breakpoints.md})
{
  display: block;
}
  text-align: center;
  padding: 0.1em 0;
  a {
    text-decoration: none;
    margin: 0 0.6vw;
    color: inherit;
    white-space: nowrap;
    border-bottom: 1px solid transparent;
    display: inline-block;
    p {
      color: ${(props) => props.scrolled && props.bg === colors.white ? "#07408A" : "inherit"};
      font-size: 1rem;
      line-height: 1.6em;
    }
  }
  .active {
    border-color: ${(props) =>
      props.color === "dark" ? colors.black : colors.white};
  }
`

const Login = styled(Link)``

const ExternalLink = styled(ELink)``

const InternalLink = styled(Link)`

`

const Logo = styled.img`
  width: 125px;
  vertical-align: bottom;
`

const Header = ({ dark, bg }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulHeader(slug: { eq: "header" }) {
        navigationLinks {
          label
          preLabel
          url
        }
        logo {
          file {
            url
          }
        }
      }
    }
  `)
  const { user } = useUserContext()
  const links = data.contentfulHeader.navigationLinks
  const linkz = [
    {label: 'See The Menu', preLabel: null, url: 'https://www.eatigc.com/shop'},
    {label: 'How It Works', preLabel: null, url: 'https://www.eatigc.com/how-it-works'},
    {label: 'Get Cooking', preLabel: null, url: 'https://www.eatigc.com/cook'},
    {label: 'Container Returns', preLabel: null, url: 'https://www.eatigc.com/collect'},
    // {label: 'Account', preLabel: null, url: 'https://www.eatigc.com/account'},
    {label: 'Order', preLabel: null, url: 'https://www.eatigc.com/shop'}
  ]
  const logo = data.contentfulHeader.logo
  const headerRef = useRef()
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  const { ref, inView: scrollTop } = useInView({
    initialInView: true,
    rootMargin: "30px",
  })

  useEffect(() => {
    if (mobileDrawerOpen) {
      setBodyOverflow(true)
    } else {
      setBodyOverflow(false)
    }
  }, [mobileDrawerOpen])
  let CartLinkContainer
  let hasItems
  if (
    CartItemsCount() > 0 &&
    typeof window === "object" &&
    !window.location.pathname.startsWith("/cart")
  ) {
    hasItems = true
    CartLinkContainer = `
  

  border: none;

  width: 25px;
  height: 22px;
  padding: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
  }
`
  } else {
    hasItems = false
    CartLinkContainer = `
  border: none;
  background: transparent;
  width: 25px;
  height: 22px;
  padding: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`
  }

  return (
    <Fragment>
      {/* Used to know if the user has scrolled */}
      <div ref={ref} />
      <HeaderContainer
        scrolled={!scrollTop}
        dark={mobileDrawerOpen || dark}
        ref={headerRef}
        bg={bg}
        transparent={scrollTop || mobileDrawerOpen}
      >
        <Section left >
          <LinksBlock>
          <Burger bg={bg} onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}>
              <Open className={mobileDrawerOpen ? "close" : "open"}>
                <div className={`burger-top line ${dark ? "dark" : ""}`} />
                <div className={`burger-bottom line ${dark ? "dark" : ""}`} />
              </Open>
            </Burger>
            <SingleLink desktop key={0} bg={bg} scrolled={!scrollTop} color={dark ? "dark" : "white"}>
              <InternalLink to={"/shop"}>
                <p>See the Menu</p>
              </InternalLink>
            </SingleLink>
            <SingleLink desktop key={1} bg={bg} scrolled={!scrollTop} color={dark ? "dark" : "white"}>
              <InternalLink to={"/how-it-works"}  >
                <p>How It Works</p>
              </InternalLink>
            </SingleLink  >
            <SingleLink desktop key={2} bg={bg} scrolled={!scrollTop} color={dark ? "dark" : "white"} >
              <InternalLink to={"/cook"}  >
                <p>Get Cooking</p>
              </InternalLink  >
            </SingleLink>
            {/* {links && links.map((link, index) => {
              const { url, label, preLabel } = link

              return (
                <SingleLink key={index} color={dark ? "dark" : "white"}>
                  {preLabel ? preLabel : ""}
                  {url.includes("https://www.eatigc.com") ? (
                    <InternalLink
                      to={url.substring(22)}
                      activeClassName="active"
                    >
                      <p>{label}</p>
                    </InternalLink>
                  ) : (
                    <ExternalLink href={url}>
                      <p>{label}</p>
                    </ExternalLink>
                  )}
                </SingleLink>
              )
            })} */}
          </LinksBlock>
        </Section>
        <Section >
          <HomeLink to="/"><Logo src={logo.file.url}/></HomeLink>
        </Section>
        <Section right>
        <LinksBlock>
            <SingleLink desktop key={3} bg={bg} scrolled={!scrollTop} color={dark ? "dark" : "white"}>
              <InternalLink to={"/collect"} >
                <p>Containers</p>
              </InternalLink>
            </SingleLink>
            <SingleLink desktop key={4} bg={bg} scrolled={!scrollTop} color={dark ? "dark" : "white"}>
              <InternalLink to={"/account"}  >
                <p>Account</p>
              </InternalLink>
            </SingleLink>
            <SingleLink key={5} bg={bg} scrolled={!scrollTop} color={dark ? "dark" : "white"}>
            <NewCartLink
                onClick={sendCartGtag}
                dark={mobileDrawerOpen}
                color={dark ? colors.black : colors.white}
                cartShake={CartLinkContainer}
                hasItems={hasItems}
              />
            </SingleLink>
            <SingleLink desktop>
            <InternalLinkButton to={"/shop"}>
              Order
            </InternalLinkButton>
            </SingleLink>
           </LinksBlock>
          {/* <Buttons>
            <Button desktop>
              {user ? (
                <SingleLink color={dark ? "dark" : "white"}>
                  <Link to="/account/orders" className="account">
                    Account
                  </Link>
                </SingleLink>
              ) : (
                <SingleLink
                  color={dark ? "dark" : "white"}
                  onClick={sendLoginGtag}
                >
                  <Login to="/account/login">Login</Login>
                </SingleLink>
              )}
            </Button>
            <Button desktop onClick={sendInstagramGtag}>
              <InstagramLink
                fill={dark ? colors.black : colors.white}
                width="16"
                height="16"
              />
            </Button>
            <Button>
              <CartLink
                onClick={sendCartGtag}
                dark={mobileDrawerOpen}
                color={dark ? colors.black : colors.white}
                cartShake={CartLinkContainer}
                hasItems={hasItems}
              />
            </Button>
            <Burger onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}>
              <Open className={mobileDrawerOpen ? "close" : "open"}>
                <div className={`burger-top line ${dark ? "dark" : ""}`} />
                <div className={`burger-bottom line ${dark ? "dark" : ""}`} />
              </Open>
            </Burger>
          </Buttons> */}
        </Section>
      </HeaderContainer>
      <MobileDrawer
        open={mobileDrawerOpen}
        paddingTop={headerRef.current?.clientHeight}
        links={linkz}
        handleDrawer={setMobileDrawerOpen}
      />
    </Fragment>
  )
}

export default Header
