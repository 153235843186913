import React from "react"
import { ReactComponent as Cart } from "src/resources/img/svg/cart.svg"
import styled from "styled-components"
import CartItemsCount from "./CartItemsCount"
import colors from "src/styles/colors"
import { Link } from "gatsby"



const Count = styled.div`
  position: absolute;
  font-size: 10px;
  line-height: 1;
  text-align: right;
  right: ${props => (props.hasItems ? "-12px" : "-10px")};
  color: ${(props) => (props.dark ? colors.black : props.color)};
  top: ${props => (props.hasItems ? "-9%" : "-34%")};
  padding: 5px;
  div {
    min-width: 10px;
  }
`

const StyledCart = styled(Cart)`
  min-width: 25px;
  left: ${props => (props.hasItems ? "27%" : "auto")};
  position: relative;
  top: ${props => (props.hasItems ? "38%" : "auto")};
`



const CartLink = ({ dark, color, cartShake, hasItems }) => {
  let CartLinkContainer = styled.div`${cartShake }`
  return (
    <>
      <Link to="/cart" id="cartLink">
     { hasItems ?  <div style={{width: "40px", height: "40px", backgroundColor: "#50C878", borderRadius: "100px"}}>
        <CartLinkContainer>
          <Count hasItems={hasItems} dark={dark} color={color}>
            <div >
              <span>
                <CartItemsCount />
              </span>
            </div>
          </Count>
          <StyledCart hasItems={hasItems} stroke={dark ? colors.black : color} fill="transparent" />
        </CartLinkContainer>
        </div> : 
        <div >
        <CartLinkContainer>
          <Count hasItems={hasItems} dark={dark} color={color}>
            <div >
              <span>
                <CartItemsCount />
              </span>
            </div>
          </Count>
          <StyledCart stroke={dark ? colors.black : color} fill="transparent" />
        </CartLinkContainer>
        </div>}
      </Link>
    </>
  )
}

export default CartLink
