import React, { useContext } from "react"
import { ReactComponent as Cart } from "src/resources/img/svg/cart.svg"
import styled from "styled-components"
import CartItemsCount from "./CartItemsCount"
import colors from "src/styles/colors"
import { Link } from "gatsby"
import { CartContext } from "../../context/CartContext"






const InternalLink = styled(Link)``
const CartLink = ({ dark, color, cartShake, hasItems }) => {
  let CartLinkContainer = styled.div`${cartShake }`
  const [cart] = useContext(CartContext)
  return (
    <>
    <InternalLink to="/cart" id="cartLink" >
    
         <p> Cart ({(cart && cart.item_quantity) ? cart.item_quantity : 0})</p>
          
     
    </InternalLink>
    
      {/* <Link to="/cart" id="cartLink">
     { hasItems ?  <div style={{width: "40px", height: "40px", backgroundColor: "#50C878", borderRadius: "100px"}}>
        <CartLinkContainer>
          <Count hasItems={hasItems} dark={dark} color={color}>
            <div >
              <span>`
                <CartItemsCount />
              </span>
            </div>
          </Count>
          <StyledCart hasItems={hasItems} stroke={dark ? colors.black : color} fill="transparent" />
        </CartLinkContainer>
        </div> : 
        <div >
        <CartLinkContainer>
          <Count hasItems={hasItems} dark={dark} color={color}>
            <div >
              <span>
                <CartItemsCount />
              </span>
            </div>
          </Count>
          <StyledCart stroke={dark ? colors.black : color} fill="transparent" />
        </CartLinkContainer>
        </div>}
      </Link> */}
    </>
  )
}

export default CartLink
