import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"
import colors from "./colors"
import breakpoints from "./breakpoints"

const fontFace = (fontFamily, woff, woff2) => {
  return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }
    `
}

const GlobalStyle = createGlobalStyle`
    ${reset}

    body {
        font-family: 'Work Sans', sans-serif;
        font-size: calc(0.24vh + 1rem);
        font-weight: 500;
        line-height: 1.6;
        -webkit-font-smoothing: antialiased;

        h1, h2, h3, h4 {
                font-family: 'Work Sans', sans-serif;
                font-weight: 900;
                font-style: normal;
                letter-spacing: .01em;
                text-transform: none;
               
        }

        h1 {
            font-size: calc(3.6vw + 16px);
            line-height: 1.02;
        }

        h2 {
              ${"" /* font-size: 2rem; */}
              font-size: calc(2.4vw + 16px);
              font-weight: 900;
              line-height: 100%;
              @media (min-width: 1600px){
                font-size: 55px;
            }
        }

        h3 {
              
              font-size: calc(1vw + 16px);
              line-height: 100%;
        }

        h4 {
            font-size: calc(0.6vw + 16px);
            font-weight: 500;
        }
    


        @media (min-width: ${breakpoints.md}) {
            h1 {
                font-size: calc(4 * 1rem);
            }

            h2 {
              font-size: calc(2.4vw + 1rem);
              font-weight: 900;
              @media (min-width: 1600px){
    font-size: 55px;
  }
            }
        }
    }
`

export default GlobalStyle
