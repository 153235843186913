import React from "react"
import styled from "styled-components"
import fonts from "src/styles/fonts"
import colors from "src/styles/colors"
import breakpoints from "src/styles/breakpoints"
import SignupForm from "./SignupForm"
import { Col, Row } from "styled-bootstrap-grid"

const Container = styled.div`
  color: ${colors.white};
  padding-bottom: 60px;
  border-bottom: 1px solid ${colors.white};

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 10px;
  }
`

const Heading = styled.h3`
  ${fonts.workSansBold};
  ${'' /* font-size: calc((1.5 - 1) * 1.2vw + 1rem); */}
  line-height: 1.176;
  text-transform: uppercase;
  color: ${colors.royalBlue};
  ${'' /* margin-bottom: 2rem; */}
`

const Text = styled.p`
  margin: 1rem 0;
  font-size: calc((1.2 - 1) * 1.2vh + 1rem);
  line-height: 1.6;
  color: black;
`

const StyledCol = styled(Col)`
  padding: 17px 0;
`

const PreFooterBlock = ({ title, content, response }) => {
  return (
    <Container>
      <Row>
        <StyledCol md={5} alignSelf="center">
          <Heading>{title}</Heading>
          <Text>{content}</Text>
          <SignupForm responseText={response} />
        </StyledCol>
        <StyledCol md={6} mdOffset={1} alignSelf="center">
         
        </StyledCol>
      </Row>
    </Container>
  )
}

export default PreFooterBlock
