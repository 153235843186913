/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import sharingImage from "../../resources/img/social/sharing.png"

function Seo({ description, lang, meta, fullTitle, title, image, path }) {
    const { site } = useStaticQuery(
        graphql `
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    )

    const websiteUrl = process.env.WEBSITE_URL

    const metaTitle = title || site.siteMetadata.title
    const metaDescription = description || site.siteMetadata.description
    const socialSharingImage = image || websiteUrl + sharingImage
    const url = websiteUrl + path
    const pageUrl = url.substr(-1) === "/" ? url.substr(0, url.length - 1) : url
        //   const googleTagManager = `
        // (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        // })(window,document,'script','dataLayer','GTM-PVFRVCR');
        // `

    return ( <
        Helmet htmlAttributes = {
            {
                lang,
            }
        }
        // script = {
        //     [{
        //         type: 'text/javascript',
        //         innerHTML: googleTagManager
        //     }]
        // }
        title = { metaTitle }
        titleTemplate = { fullTitle ? `%s` : `%s | ${site.siteMetadata.title}` }
        meta = {
            [{
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:url`,
                    content: pageUrl,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: socialSharingImage,
                },
                {
                    property: `fb:app_id`,
                    content: `2243518289231235`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `twitter:image:src`,
                    content: socialSharingImage,
                },
                {
                    name: "facebook-domain-verification",
                    content: "tm0z1tr8hb93ryam8473gtpl5lzf32",
                },
            ].concat(meta)
        }
        />
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    title: PropTypes.string,
    path: PropTypes.string,
}

export default Seo