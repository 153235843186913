import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GlobalStyle from "../../styles/global"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { BaseCSS } from "styled-bootstrap-grid"
import { GridThemeProvider } from "styled-bootstrap-grid"
import { ThemeProvider } from "styled-components"
import { gsap } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import Header from "./Header"
import Footer from "./Footer"
import { CartContextProvider } from "../../context/CartContext"
import colors from "../../styles/colors"
import { updateCart, applyCoupon } from "../../services/cart/cart"
import { useQueryParams } from 'src/hooks/useQueryParams';

export const MOBILE_HEADER_HEIGHT = 80
export const DESKTOP_HEADER_HEIGHT = 94

gsap.registerPlugin(CSSPlugin)

const PageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: ${(props) => (props.bg ? props.bg : colors.white)};
`

const GlobalPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const contentStyle = {
  margin: "auto",
  boxSizing: "border-box",
  overflow: "auto",
  position: "relative",
  background: colors.white,
  width: "480px",
  maxWidth: "calc(100% - 22px * 2)",
  boxShadow: "0 2px 114px 0 rgb(0 0 0 / 30%)",
  borderRadius: "4px",
  padding: "44px",
  paddindBottom: "22px"
}

const contentStyleMobile = {
  ...contentStyle,
  padding: "33px",
  paddindBottom: "16.5px"
}

const overlayStyle = {
  overflowY: "auto",
  outline: "none",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  transition: "background-color 100ms ease-out",
}

const gridTheme = {
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 0,
  },
  col: {
    padding: 0,
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
}

const styledTheme = {}

const Layout = ({ children, dark, bg }) => {
  const queryParams = useQueryParams();
  const getContentStyle = () => {
    if (typeof window === "undefined") {
      return
    }
    if (window.innerWidth > 414) {
      return contentStyle
    }
    return contentStyleMobile
  }

  useEffect(() => {


    if (typeof window !== 'undefined' && window.localStorage) {
      if (queryParams && queryParams.promo)
      {
        localStorage.setItem('promoCode' , queryParams.promo)
      }
    }
    try{
      if (queryParams && queryParams.promo)
      {
        applyCoupon(queryParams.promo)
      }

    } catch(e){
      console.log(e)
    }
   
    updateCart()
    
    
  }, [queryParams])

  return (
    <React.Fragment>
      <ThemeProvider theme={styledTheme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <GlobalStyle />
          <BaseCSS />
          <GlobalPageContainer>
            <CartContextProvider>
                  <Header
                    dark={dark}
                    bg={bg}
                  />
                  <PageContainer bg={bg}>{children}</PageContainer>
                  <Footer />
            </CartContextProvider>
          </GlobalPageContainer>
        </GridThemeProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
