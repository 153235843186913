import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ELink from "./ExternalLink"
import { ctaButtonStyle } from "./Styles"

const StyledButton = styled.button`
  ${ctaButtonStyle};
`

const OnClickButton = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>
}

export default OnClickButton
