import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

export const ctaButtonStyle = (props) => {
  //padding: 1.4em 2.338em;
   // font-size: calc((1 - 1) * 1.2vw + 1rem);
  return `
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;
  ${fonts.workSansRegular}
  line-height: 1.6em;
  text-transform: uppercase;
  text-decoration: none;
  background: #319ad5;
  
  padding: 0.35em 1.2em;
  font-size: calc(0.6vw + 1rem);
 
  cursor: pointer;
  display: inline-block;
  color: ${props.blue ? colors.royalBlue : colors.white};
  border-color: #319ad5;
  font-weight: 700;

  :hover {
    background: ${props.blue ? colors.royalBlue : colors.white};
    color: ${props.blue ? colors.white : colors.royalBlue};
  }
`
}

//border-color: ${props.blue ? colors.royalBlue : colors.white};