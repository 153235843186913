const colors = {
  white: '#FFFFFF',
  black: '#000000',
  lightBlue: '#319AD5',
  navyBlue: '#2A3254',
  royalBlue: '#07408A',
  pink: '#EB9CC3',
  darkGreen: '#1F4922',
  rust: '#C85440',
  yellow: '#DCCB49',
  lightGrey: '#F4F4F3',
  midGrey: '#595959',
  darkGrey: '#292929',
  textGrey: "#3E3E3E",
  errorRed: "#f0523d",
  borderGrey: "#EDEDED",
  accountGrey: "#FAF8F5"
};

export default colors;
