/* global fbq */
import React, { useState } from "react"
import styled from "styled-components"
import fonts from "src/styles/fonts"
import colors from "src/styles/colors"
import breakpoints from "src/styles/breakpoints"
import OnClickButton from "src/components/Core/OnClickButton"
import axios from "axios"
import { hashEmail } from "src/services/util/hash"

const StyledForm = styled.form`
  text-align: left;
  margin-right: 0;
  margin-left: auto;
  position: relative;
`

const InputContainer = styled.div`
  ${"" /* margin-top: 1rem; */}
  padding: 0.5rem 0 0.5rem 0.25rem;
  min-width: 14rem;
  margin-right: 0;
  display: inline-block;
  input {
    width: 100%;
    padding: 0.7rem 0.8rem;
    line-height: 1.2rem;
    font-size: calc((1 - 1) * 1.2vh + 1rem);
    border: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 25px;

    :focus {
      outline-color: ${colors.white};
      outline-width: 2px;
      outline-offset: 4px;
      outline-style: solid;
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.3);
      letter-spacing: 0.03em;
    }
  }
`

const SubmitContainer = styled.div`
  padding: 0.5rem 0 0.5rem 1rem;
  ${"" /* margin-top: 1rem; */}
  display: inline-block;
`

const Submit = styled(OnClickButton)`
  line-height: 1.2rem;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.12);
  text-transform: unset;
  box-sizing: border-box;
  padding: 0.7rem 0.8rem;
  font-weight: unset;
`

const Message = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  font-size: 1rem;
  position: absolute;
  background: ${colors.lightBlue};
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: right;
  }

  @media (min-width: ${breakpoints.lg}) {
    p {
      width: 70%;
      max-width: 500px;
    }
  }
`

const SignupForm = ({ responseText }) => {
  const [email, setEmail] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [message, setMessage] = useState("")
  const LA = [
    90001, 90002, 90003, 90004, 90005, 90006, 90007, 90008, 90009, 90010, 90011,
    90012, 90013, 90014, 90015, 90016, 90017, 90018, 90019, 90020, 90021, 90022,
    90023, 90024, 90025, 90026, 90027, 90028, 90029, 90030, 90031, 90032, 90033,
    90034, 90035, 90036, 90037, 90038, 90039, 90040, 90041, 90042, 90043, 90044,
    90045, 90046, 90047, 90048, 90049, 90050, 90051, 90052, 90053, 90054, 90055,
    90056, 90057, 90058, 90059, 90060, 90061, 90062, 90063, 90064, 90065, 90066,
    90067, 90068, 90069, 90070, 90071, 90072, 90073, 90074, 90075, 90076, 90077,
    90078, 90079, 90080, 90081, 90082, 90083, 90084, 90086, 90087, 90088, 90089,
    90090, 90091, 90093, 90094, 90095, 90096, 90099, 90189, 90201, 90202, 90209,
    90210, 90211, 90212, 90213, 90220, 90221, 90222, 90223, 90224, 90230, 90231,
    90232, 90233, 90239, 90240, 90241, 90242, 90245, 90247, 90248, 90249, 90250,
    90251, 90254, 90255, 90260, 90261, 90262, 90265, 90266, 90267, 90270, 90272,
    90274, 90275, 90277, 90278, 90280, 90291, 90292, 90293, 90294, 90295, 90296,
    90301, 90302, 90303, 90304, 90305, 90306, 90307, 90308, 90309, 90310, 90311,
    90312, 90401, 90402, 90403, 90404, 90405, 90406, 90407, 90408, 90409, 90410,
    90411, 90501, 90502, 90503, 90504, 90505, 90506, 90601, 90606, 90610, 90640,
    90650, 90651, 90652, 90660, 90661, 90662, 90671, 90706, 90707, 90710, 90717,
    90723, 90731, 90732, 90746, 90747, 90805, 91001, 91003, 91006, 91007, 91008,
    91009, 91011, 91012, 91016, 91017, 91020, 91021, 91023, 91024, 91025, 91030,
    91031, 91040, 91041, 91042, 91043, 91046, 91066, 91077, 91101, 91102, 91103,
    91104, 91105, 91106, 91107, 91108, 91109, 91110, 91114, 91115, 91116, 91117,
    91118, 91121, 91123, 91124, 91125, 91126, 91129, 91182, 91184, 91185, 91188,
    91189, 91199, 91201, 91202, 91203, 91204, 91205, 91206, 91207, 91208, 91209,
    91210, 91214, 91221, 91222, 91224, 91225, 91226, 91301, 91302, 91303, 91304,
    91305, 91306, 91308, 91309, 91313, 91316, 91324, 91325, 91326, 91327, 91328,
    91329, 91330, 91331, 91333, 91334, 91335, 91337, 91340, 91341, 91343, 91344,
    91345, 91346, 91352, 91353, 91356, 91357, 91361, 91364, 91365, 91367, 91371,
    91385, 91392, 91393, 91394, 91395, 91396, 91401, 91402, 91403, 91404, 91405,
    91406, 91407, 91408, 91409, 91410, 91411, 91412, 91413, 91416, 91423, 91426,
    91436, 91470, 91482, 91495, 91496, 91499, 91501, 91502, 91503, 91504, 91505,
    91506, 91507, 91508, 91510, 91521, 91522, 91523, 91526, 91601, 91602, 91603,
    91604, 91605, 91606, 91607, 91608, 91609, 91610, 91611, 91612, 91614, 91615,
    91616, 91617, 91618, 91714, 91715, 91716, 91731, 91732, 91733, 91734, 91735,
    91754, 91755, 91756, 91770, 91771, 91772, 91775, 91776, 91778, 91780, 91801,
    91802, 91803, 91804, 91896, 91899, 91342,
  ]
  const palmDesert = [92210, 92211, 92260, 92270]
  const SD = [
    91902, 91908, 91909, 91910, 91911, 91912, 91913, 91914, 91915, 91921, 91932,
    91933, 91935, 91941, 91942, 91943, 91944, 91945, 91946, 91950, 91951, 91976,
    91977, 91978, 91979, 92003, 92007, 92008, 92009, 92010, 92011, 92013, 92014,
    92018, 92020, 92021, 92022, 92023, 92024, 92025, 92026, 92027, 92028, 92029,
    92030, 92033, 92037, 92038, 92039, 92040, 92046, 92049, 92051, 92052, 92054,
    92056, 92061, 92064, 92067, 92069, 92071, 92072, 92074, 92075, 92078, 92079,
    92081, 92082, 92083, 92084, 92085, 92091, 92092, 92093, 92096, 92101, 92102,
    92103, 92104, 92105, 92106, 92107, 92108, 92109, 92110, 92111, 92112, 92113,
    92114, 92115, 92116, 92117, 92118, 92119, 92120, 92121, 92122, 92123, 92124,
    92126, 92127, 92128, 92129, 92130, 92131, 92132, 92134, 92135, 92136, 92137,
    92138, 92139, 92140, 92142, 92143, 92145, 92147, 92149, 92150, 92152, 92153,
    92154, 92155, 92158, 92159, 92160, 92161, 92163, 92165, 92166, 92167, 92168,
    92169, 92170, 92171, 92172, 92173, 92174, 92175, 92176, 92177, 92178, 92179,
    92182, 92186, 92187, 92191, 92192, 92193, 92195, 92196, 92197, 92198, 92199,
    91767, 91768, 91769, 91773, 91722, 91723, 91724, 91706, 91790, 91791, 91792,
    91789, 91795, 91765, 91748, 91788, 91789, 91709, 91710, 92886, 92887, 92870,
    92871, 92811, 92821, 92822, 92823, 92831, 92832, 92833, 92834, 92835, 92836,
    92837, 92838, 92801, 92802, 92803, 92804, 92805, 92806, 92807, 92809, 92812,
    92814, 92815, 92816, 92817, 92825, 92850, 90680, 90630, 90712, 90713, 90714,
    90715, 90620, 90621, 90622, 90623, 90624, 90631, 90632, 90633, 90637, 90638,
    90639, 90621, 90622, 90623, 90624, 90631, 90632, 90633, 90637, 90638, 90639,
    90601, 90602, 90603, 90604, 90605, 90606, 90607, 90608, 90609, 90610, 91745,
    91746, 91747, 91749, 90803, 90804, 90806, 90807, 90808, 90809, 90810, 90813,
    90814, 90815, 90822, 90831, 90832, 90833, 90834, 90835, 90840, 90842, 90844,
    90846, 90847, 90848, 90853, 90899, 90744, 90748, 90810, 90895, 90804, 90815,
    91740, 91741, 90755,
  ]
  const allowedZones = LA.concat(SD)

  const orangeCounty = [
    92683, 92804, 92704, 92703, 92801, 90631, 92805, 92677, 92630, 92627, 92707,
    92647, 92530, 92780, 92708, 92646, 92840, 92626, 92701, 92870, 92705, 92833,
    92691, 92656, 90630, 92886, 92843, 90620, 92648, 92688, 92620, 92692, 92821,
    92867, 92806, 92802, 92807, 92618, 92831, 92672, 90621, 92841, 92869, 92675,
    92649, 92660, 92694, 92706, 92602, 92679, 92604, 92673, 92883, 92653, 92612,
    92651, 92629, 90680, 92868, 92782, 92835, 92614, 92832, 90740, 92844, 92606,
    90720, 92887, 92663, 92865, 92878, 92808, 92845, 92866, 92637, 92617, 90623,
    92603, 92657, 92625, 92610, 92676, 92624, 92861, 92655, 92823, 92661, 92662,
    92697, 90742, 92834, 92725, 92605, 92609, 92607, 92615, 92616, 92619, 92623,
    92628, 92650, 92652, 92654, 92659, 92658, 92674, 92678, 92684, 92685, 92690,
    92693, 92698, 92702, 92709, 92711, 92710, 92712, 92735, 92728, 92781, 92799,
    92803, 92811, 92809, 92814, 92812, 92816, 92815, 92817, 92822, 92825, 92837,
    92836, 92838, 92842, 92846, 92856, 92850, 92859, 92857, 92863, 92862, 92864,
    92871, 92885, 92899, 90622, 90624, 90632, 90633, 90721, 90743,
  ]
  const longBeach = [
    90713, 90731, 90740, 90755, 90802, 90803, 90804, 90805, 90806, 90807, 90808,
    90810, 90813, 90814, 90815, 90822, 90831,
  ]
  const bayArea = [
    93635, 94002, 94005, 94010, 94014, 94015, 94018, 94019, 94020, 94021, 94022,
    94024, 94025, 94027, 94028, 94030, 94080, 94035, 94108, 94037, 94038, 94040,
    94111, 94112, 94114, 94041, 94043, 94044, 94060, 94061, 94062, 94063, 94065,
    94066, 94070, 94074, 94085, 94086, 94087, 94089, 94102, 94105, 94107, 94103,
    94104, 94109, 94115, 94110, 94116, 94402, 94403, 94404, 94501, 94117, 94118,
    94121, 94122, 94123, 94124, 94127, 94128, 94129, 94130, 94131, 94132, 94133,
    94134, 94548, 94143, 94549, 94550, 94551, 94552, 94553, 94555, 94556, 94558,
    94559, 94560, 94561, 94563, 94564, 94565, 94566, 94567, 94568, 94569, 94571,
    94158, 94301, 94303, 94304, 94572, 94574, 94305, 94306, 94401, 94502, 94503,
    94505, 94506, 94507, 94508, 94509, 94510, 94511, 94512, 94513, 94514, 94515,
    94517, 94518, 94519, 94520, 94521, 94523, 94525, 94526, 94528, 94530, 94531,
    94533, 94534, 94576, 94577, 94535, 94536, 94538, 94539, 94541, 94542, 94544,
    94545, 94546, 94578, 94579, 94580, 94582, 94583, 94585, 94586, 94587, 94588,
    94589, 94590, 94591, 94592, 94547, 94595, 94596, 94597, 94598, 94599, 94601,
    94602, 94603, 94605, 94606, 94607, 94608, 95023, 95030, 95032, 95033, 95035,
    95037, 95045, 95046, 95050, 95051, 94609, 94610, 94611, 94612, 95053, 94613,
    94618, 94619, 94621, 94702, 95110, 95111, 94703, 95112, 94704, 94705, 95135,
    95136, 95138, 94706, 94707, 94708, 95192, 95206, 95219, 95242, 95304, 95322,
    95360, 95363, 95377, 95391, 95401, 95403, 95404, 94709, 94710, 94720, 94801,
    94803, 94804, 94805, 94806, 94901, 94903, 94904, 94920, 95405, 95407, 95409,
    95412, 95444, 95445, 95446, 95448, 95449, 95450, 94922, 94923, 94924, 94925,
    94928, 94929, 94930, 94931, 94933, 94937, 95426, 95436, 95452, 95457, 95461,
    95462, 95465, 94938, 94939, 94940, 94941, 94945, 94946, 94947, 95471, 95472,
    95476, 95492, 95497, 95606, 95607, 95612, 95615, 95616, 95618, 94949, 94951,
    94952, 94954, 94956, 94957, 94960, 94963, 95620, 94964, 94965, 94970, 94971,
    95641, 95679, 95687, 95688, 95690, 95694, 94972, 94973, 95439, 95441, 95442,
    95002, 95006, 95008, 95013, 95014, 95020, 95054, 95060, 95070, 95076, 95113,
    95116, 95117, 95118, 95119, 95120, 95121, 95122, 95123, 95124, 95125, 95126,
    95127, 95128, 95129, 95130, 95131, 95132, 95133, 95134, 95139, 95140, 95141,
    95148, 95419, 95421, 95425,
  ]
  const santaBarbara = [
    93013, 93014, 93067, 93101, 93102, 93103, 93105, 93106, 93107, 93108, 93108,
    93109, 93110, 93110, 93111, 93111, 93116, 93116, 93117, 93117, 93117, 93117,
    93118, 93118, 93120, 93121, 93130, 93140, 93150, 93150, 93160, 93190, 93254,
    93254, 93427, 93429, 93434, 93436, 93437, 93437, 93438, 93440, 93441, 93454,
    93455, 93455, 93456, 93457, 93457, 93458, 93460, 93463, 93463, 93464,
  ]
  const inlandEmpire = [
    92501, 92503, 92504, 92505, 92506, 92507, 92508, 92509, 92879, 92880, 92881,
    92882, 92883, 92860, 91708, 91710, 91710, 91758, 91761, 91762, 91764, 91763,
    91711, 92590, 92591, 92592, 92562, 92563, 92530, 92532,
  ]
  const handleSubmit = async (event) => {
    event.preventDefault()

    let market_signup
    if (LA.includes(parseInt(zipCode))) {
      market_signup = "LA"
    } else if (SD.includes(parseInt(zipCode))) {
      market_signup = "SD"
    } else if (inlandEmpire.includes(parseInt(zipCode))) {
      market_signup = "IE"
    } else if (santaBarbara.includes(parseInt(zipCode))) {
      market_signup = "SB"
    } else if (longBeach.includes(parseInt(zipCode))) {
      market_signup = "LB"
    } else if (bayArea.includes(parseInt(zipCode))) {
      market_signup = "Bay Area"
    } else if (palmDesert.includes(parseInt(zipCode))) {
      market_signup = "Palm Desert"
    } else {
      market_signup = "Other"
    }

    const url = "/.netlify/functions/newsletter"

    const data = {
      email: email,
      zipcode: zipCode,
      market_signup,
    }
    let old_gtag_data = {
      event_category: "email subscribe",
      event_label: "footer",
      // email: email,
      zip_code: zipCode,
      market_signup,
      send_to: "UA-185335499-1",
    }

    try {
      const response = await axios.post(url, data)

      // gtag('event', 'sign_up', data );
      if (typeof window.gtag !== "undefined") {
        try {
          window.gtag("event", "form_submit", old_gtag_data)
        } catch (e) {
          console.log(e)
        }

        let gtag_data = {
          method: "footer_email_subscribe",
          send_to: "G-LZ50YRTV2Y",
        }

        window.gtag("event", "sign_up", gtag_data)
        console.log("subscribed gtag sent")
      }
      try {
        fbq("track", "Lead", {
          content_name: "Email Signup",
          content_category: "Newsletter",
          user_data: {
            em: hashEmail(data.email),
          },
        })
      } catch (e) {
        console.log(e)
      }

      setEmail("")
      setMessage(responseText)
    } catch {
      setEmail("")
      setMessage("Server error")
    } finally {
      setTimeout(() => {
        setMessage("")
      }, 6000)
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <InputContainer>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputContainer>
      <br />
      <InputContainer style={{ minWidth: "10rem" }}>
        <input
          placeholder="Zip Code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        />
      </InputContainer>
      <SubmitContainer>
        <Submit type="submit">→</Submit>
      </SubmitContainer>
      {message !== "" && (
        <Message>
          <p>{message}</p>
        </Message>
      )}
    </StyledForm>
  )
}

export default SignupForm
