const swell = require("swell-js")

const options = {
  useCamelCase: false,
}

swell.init(process.env.SWELL_STORE_ID, process.env.SWELL_PUBLIC_KEY, options)

const getCart = async () => {
  return await swell.cart.get()
}

const addItem = async (productId, quantity, options) => {
  return await swell.cart.addItem({
    product_id: productId,
    quantity: quantity,
    options: options,
  })
}

const applyCoupon = async (couponCode) => {
  try{
    return await swell.cart.applyCoupon(couponCode)
  } catch (e)
  {
    console.log(e)
  }
  
}

const updateItem = async (productId, quantity, options) => {
  //pass product.id, not product.product_id
  return await swell.cart.updateItem(productId, { quantity, options })
}

const removeItem = async (productId) => {
  //pass product.id, not product.product_id
  return await swell.cart.removeItem(productId)
}

const updateAllItems = async (newItems) => {
  return await swell.cart.setItems(newItems)
}

const updateCartData = async (data) => {
  return await swell.cart.update(data)
}

const submitOrder = async () => {
  return await swell.cart.submitOrder()
}

module.exports = {
  applyCoupon: applyCoupon,
  getCart: getCart,
  addItem: addItem,
  removeItem: removeItem,
  updateItem: updateItem,
  updateAllItems: updateAllItems,
  updateCartData: updateCartData,
  submitOrder: submitOrder
}
