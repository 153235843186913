const breakpoints = {
  max: "1600px",
  xxl: "1440px",
  xl: "1200px",
  lg: "992px",
  md: "768px",
  sm: "576px",
  xs: "575px",
}

export default breakpoints
