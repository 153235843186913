import React from "react"
import ExternalLink from "./ExternalLink"
import { ReactComponent as Instagram } from "../../resources/img/svg/black-instagram.svg"
import styled from "styled-components"

const StyledInstagram = styled(Instagram)`
  margin-top: 2px;
  min-width: ${(props) => (props.width ? `${props.width}px` : "20px")};
`

const StyledExternalLink = styled(ExternalLink)`
  width: ${(props) => (props.width ? `${props.width}px` : "20px")};
  height: ${(props) => (props.height ? `${props.height}px` : "20px")};
  display: flex;
  justify-content: center;
  align-items: center;
`

const InstagramLink = ({ fill, width, height }) => {
  return (
    <StyledExternalLink
      href="https://www.instagram.com/eatIGC/"
      width={width}
      height={height}
    >
      <StyledInstagram fill={fill} width={width} height={height} />
    </StyledExternalLink>
  )
}

export default InstagramLink
