import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import PreFooterBlock from "../Core/Footer/PreFooterBlock"
import ELink from "../Core/ExternalLink"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Row } from "styled-bootstrap-grid"
import SignupForm from "../Core/Footer/SignupForm"
import InstagramLink from "../Core/InstagramLink"
import FacebookLink from "../Core/FacebookLink"

const Container = styled.div`
  color: ${colors.white};
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.white};

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 10px;
  }
`

const ArrowSpan = styled.span`
  right: 0;
  position: absolute;
  font-family: Segoe UI Symbol;
`
const FirstStyledCol = styled(Col)`
  padding: 17px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    :first-of-type {
      padding-left: 0;
    }

    :last-of-type {
      padding-right: 0;
    }
  }
  ${
    "" /*   
  @media (min-width: ${breakpoints.lg}) {
    margin-left: 100px;
  } */
  }
`
const MobileLinks = styled.div`
  display: none;
  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`

const DesktopLinks = styled.div`
  display: block;
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`

const Heading = styled.h3`
  ${fonts.workSansBold};
  ${"" /* font-size: calc((1.5 - 1) * 1.2vw + 1rem); */}
  line-height: 1.176;
  text-transform: uppercase;
  color: ${colors.royalBlue};
  ${"" /* margin-bottom: 2rem; */}
`

const Text = styled.p`
  margin: 1rem 0;
  font-size: calc((1.2 - 1) * 1.2vh + 1rem);
  line-height: 1.6;
  color: black;
`

const FooterContainer = styled.footer`
  background-color: ${colors.white};
  padding: 6.6vmax 6vw;

  @media (min-width: ${breakpoints.md}) {
    padding: 3vmax 5vw;
  }
`

const FooterBlock = styled.div`
  padding-top: 25px;
`

const LinksBlock = styled.div``

const LinksHeader = styled.h4`
  ${fonts.workSansBold};
  color: black;
  margin-bottom: 2rem;
  font-size: calc(0.12vw + 1rem);
  line-height: 1.1952;
  text-transform: uppercase;
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`
const SocialDiv = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: left;
  
`
const LinkP = styled.p`
  color: black;
  margin-bottom: ${(props) => (props.mb ? "1rem" : "0")};
  a {
    display: inline-block;
    color: inherit;
    line-height: 1.6;
    font-size: ${(props) =>
      props.small
        ? "calc((.9 - 1) * 1.2vh + 1rem)"
        : "calc((1 - 1) * 1.2vh + 1rem)"};
    text-underline-offset: 0.2em;
  }
`

const ExternalLink = styled(ELink)`
  text-decoration: none;
`

const InternalLink = styled(Link)`
  text-decoration: none;
`

const StyledCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    padding: 17px;
    :first-of-type {
      padding-left: 0;
    }

    :last-of-type {
      padding-right: 0;
    }
  }
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Terms = styled.div`
  padding: 17px 0;
  color: black;
  line-height: 1.6;
  font-style: italic;
  font-size: 1rem;

  a {
    color: inherit;
    text-underline-offset: 0.2em;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulFooter(slug: { eq: "footer" }) {
        contactLinks {
          label
          preLabel
          url
        }
        links {
          label
          preLabel
          url
        }
        brandLogo {
          gatsbyImageData(width: 245)
        }
        emailSubscribeTitle
        emailSubscribeContent
        emailSubscribeResponse
      }
    }
  `)
  const footer = data.contentfulFooter
  const title = footer.emailSubscribeTitle
  const content = footer.emailSubscribeContent
  const response = footer.emailSubscribeResponse

  const [detail1, setDetail1] = useState(false)
  const [detail2, setDetail2] = useState(false)
  const [detail3, setDetail3] = useState(false)

  const detail1click = () => {

    let detailEl1 = document.getElementById("detail1")

    if (detailEl1) {
      if (detailEl1.hasAttribute("open")) {
        setDetail1(true)
      } else {
        setDetail1(false)
      }
    }
  }
  const detail2click = () => {
    let detailEl2 = document.getElementById("detail2")

    if (detailEl2) {
      if (detailEl2.hasAttribute("open")) {
        setDetail2(true)
      } else {
        setDetail2(false)
      }
    }
  }
  const detail3click = () => {
    let detailEl3 = document.getElementById("detail3")

    if (detailEl3) {
      if (detailEl3.hasAttribute("open")) {
        setDetail3(true)
      } else {
        setDetail3(false)
      }
    }
  }

  return (
    <FooterContainer>
      {/* <PreFooterBlock
        title={footer.emailSubscribeTitle}
        content={footer.emailSubscribeContent}
        response={footer.emailSubscribeResponse}
      /> */}
      <Container>
        <Row style={{ paddingBottom: "75px" }}>
          <StyledCol md={5} alignSelf="center">
            <Heading>{title}</Heading>
            <Text>{content}</Text>
            <SignupForm responseText={response} />
          </StyledCol>

          <StyledCol md={6} mdOffset={1}>
            {/* <FooterBlock> */}
            <Row>
              <StyledCol md={4}>
                <DesktopLinks>
                  <LinksBlock>
                    <LinksHeader style={{ color: "#07408A" }}>
                      Let's Eat
                    </LinksHeader>

                    <LinkP small key={0}>
                      <InternalLink to={"/shop"}>Shop</InternalLink>
                    </LinkP>
                    <LinkP small key={1}>
                      <InternalLink to={"/cook"}>
                        Cooking Instructions
                      </InternalLink>
                    </LinkP>
                    {/* {footer.links.map((link, index) => {
                const { url, label, preLabel } = link

                return (
                  <LinkP key={index}>
                    {preLabel ? preLabel : ""}{" "}
                    {url.includes("https://www.eatigc.com") ? (
                      <InternalLink to={url.substring(22)}>
                        {label}
                      </InternalLink>
                    ) : (
                      <ExternalLink href={url}>{label}</ExternalLink>
                    )}
                  </LinkP>
                )
              })} */}
                  </LinksBlock>
                </DesktopLinks>
                <MobileLinks>
                  <LinksBlock>
                    <details onClick={detail1click} id="detail1">
                      <summary>
                        <LinksHeader style={{ color: "#07408A" }}>
                          Let's Eat
                          <ArrowSpan> { detail1 ? <>&#x25B2;</> : <>&#9658;</> } </ArrowSpan>
                        </LinksHeader>
                      </summary>
                      <LinkP small key={0}>
                        <InternalLink to={"/shop"}>Shop</InternalLink>
                      </LinkP>
                      <LinkP small key={1}>
                        <InternalLink to={"/cook"}>
                          Cooking Instructions
                        </InternalLink>
                      </LinkP>
                      {/* {footer.links.map((link, index) => {
                const { url, label, preLabel } = link

                return (
                  <LinkP key={index}>
                    {preLabel ? preLabel : ""}{" "}
                    {url.includes("https://www.eatigc.com") ? (
                      <InternalLink to={url.substring(22)}>
                        {label}
                      </InternalLink>
                    ) : (
                      <ExternalLink href={url}>{label}</ExternalLink>
                    )}
                  </LinkP>
                )
              })} */}
                    </details>
                  </LinksBlock>
                </MobileLinks>
              </StyledCol>

              <StyledCol md={4}>
                <DesktopLinks>
                  <LinksBlock>
                    <LinksHeader style={{ color: "#EB9CC3" }}>
                      Learn
                    </LinksHeader>

                    <LinkP small key={2}>
                      <InternalLink to={"/how-it-works"}>
                        How It Works
                      </InternalLink>
                    </LinkP>
                    <LinkP small key={3}>
                      <InternalLink to={"/collect"}>Collect</InternalLink>
                    </LinkP>
                    <LinkP small key={4}>
                      <InternalLink to={"/faqs"}>FAQ</InternalLink>
                    </LinkP>
                    {/* <LinksHeader>Get in touch</LinksHeader>
              {footer.contactLinks.map((link, index) => {
                const { url, label, preLabel } = link

                return (
                  <LinkP small key={index} mb={!preLabel}>
                    {preLabel ? preLabel : ""}{" "}
                    {url.includes("https://www.eatigc.com") ? (
                      <InternalLink to={url.substring(22)}>
                        {label}
                      </InternalLink>
                    ) : (
                      <ExternalLink href={url}>{label}</ExternalLink>
                    )}
                  </LinkP>
                )
              })} */}
                  </LinksBlock>
                </DesktopLinks>
                <MobileLinks>
                  <LinksBlock>
                    <details onClick={detail2click} id="detail2">
                      <summary>
                        <LinksHeader style={{ color: "#EB9CC3" }}>
                          Learn
                          <ArrowSpan> {detail2 ? <>&#x25B2;</> : <>&#9658;</> } </ArrowSpan>
                        </LinksHeader>
                      </summary>
                      <LinkP small key={2}>
                        <InternalLink to={"/how-it-works"}>
                          How It Works
                        </InternalLink>
                      </LinkP>
                      <LinkP small key={3}>
                        <InternalLink to={"/collect"}>Collect</InternalLink>
                      </LinkP>
                      <LinkP small key={4}>
                        <InternalLink to={"/faqs"}>FAQ</InternalLink>
                      </LinkP>
                      {/* <LinksHeader>Get in touch</LinksHeader>
           {footer.contactLinks.map((link, index) => {
             const { url, label, preLabel } = link

             return (
               <LinkP small key={index} mb={!preLabel}>
                 {preLabel ? preLabel : ""}{" "}
                 {url.includes("https://www.eatigc.com") ? (
                   <InternalLink to={url.substring(22)}>
                     {label}
                   </InternalLink>
                 ) : (
                   <ExternalLink href={url}>{label}</ExternalLink>
                 )}
               </LinkP>
             )
           })} */}
                    </details>
                  </LinksBlock>
                </MobileLinks>
              </StyledCol>
              <StyledCol md={4}>
                <DesktopLinks>
                  <LinksHeader style={{ color: "#319AD5" }}>
                    Connect
                  </LinksHeader>

                  <LinkP small key={5}>
                    <InternalLink to={"/contact"}>Contact</InternalLink>
                  </LinkP>
                  <LinkP small key={3}>
                    <ExternalLink href={"mailto:info@eatigc.com"}>
                      info@eatigc.com
                    </ExternalLink>
                  </LinkP>
                  <LinkP small key={4}>
                    <ExternalLink href={"tel:3103406198"}>
                    (310) 340-6198 
                    </ExternalLink>
                  </LinkP>
                  <SocialDiv >
                  <InstagramLink style={{marginLeft: "15px", }} fill={colors.black} width="22" height="22" />
                  <FacebookLink style={{marginLeft: "15px"}} fill={colors.black} width="22" height="22" />

                  </SocialDiv>
                </DesktopLinks>
                <MobileLinks>
                  <details onClick={detail3click} id="detail3">
                    <summary>
                      <LinksHeader style={{ color: "#319AD5" }}>
                        Connect
                        <ArrowSpan> {detail3 ? <>&#x25B2;</> : <>&#9658;</> } </ArrowSpan>
                      </LinksHeader>
                    </summary>

                    <LinkP small key={5}>
                      <InternalLink to={"/contact"}>Contact</InternalLink>
                    </LinkP>
                    <LinkP small key={3}>
                      <ExternalLink href={"mailto:info@eatigc.com"}>
                        info@eatigc.com
                      </ExternalLink>
                    </LinkP>
                    <LinkP small key={4}>
                      <ExternalLink href={"tel:3103406198"}>
                      (310) 340-6198 
                      </ExternalLink>
                    </LinkP>
                  </details>
                </MobileLinks>
              </StyledCol>
            </Row>
            {/* <Terms>
          All rights reserved, {new Date().getFullYear()} /{" "}
          <Link to={"/terms-and-conditions"}>Terms & Conditions</Link>
        </Terms> */}
            {/* </FooterBlock> */}
          </StyledCol>
        </Row>
        <hr />
      </Container>
      <Row>
        <Col md={4}>
          <InternalLink style={{color: "black"}} to={"/terms-and-conditions"}>
            Terms and Conditions
          </InternalLink>
          
        </Col>
        <Col md={4}></Col>
        <Col md={4}>
        ©2023 ALL RIGHTS RESERVED
        </Col>

      </Row>
      <script
        async
        type="text/javascript"
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RxRBWG"
      ></script>
    </FooterContainer>
  )
}

export default Footer
