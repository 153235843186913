function getItem(key) {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key)
  }
}

function setItem(key, data) {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, data)
  }
}

function removeItem(key) {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key)
  }
}

module.exports = {
  getItem: getItem,
  setItem: setItem,
  removeItem: removeItem
};
