import React from "react"
import styled from "styled-components"
import colors from "src/styles/colors"
import breakpoints from "src/styles/breakpoints"
import { Link } from "gatsby"
import ELink from "../ExternalLink"
import InstagramLink from "../InstagramLink"
import { useUserContext } from "../../../context/UserContext"

const InternalLinkButton = styled(Link)`
display: ${(props) => (props.desktop ? "none" : "block")};
width: fit-content;
font-weight:700;
@media (min-width: ${breakpoints.md})
{
  display: block;
}
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));

  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 0.35em 1.2em;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  cursor: pointer;
  display: inline-block;
  background: #319ad5;
  border-color: #319ad5;
  color: white!important;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.6em;
  ${'' /* font-size: 20px;
  line-height: 18px; */}

  @media (max-width: ${breakpoints.md}) {
    ${'' /* margin-bottom: 20px; */}
  }
`

const Button = styled.div`
  width: 50px;
  display: ${(props) => (props.desktop ? "none" : "flex")};
  align-items: center;
  justify-content: center;

  .account {
    font-size: 16px;
    line-height: 1.6;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    margin-left: 5vw;
    width: unset;
  }
`
const MobileDraweContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${colors.white};
  position: fixed;
  top: 0;
  z-index: 998;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  padding-top: ${(props) => `${props.pt}px`};
  transition: visibility 600ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Content = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const Links = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 7vmin;
`

const SingleLink = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5vw;
  color: ${colors.black};
  font-weight: 900;
  color: ${colors.royalBlue};

  a,
  .login {
    color: inherit;
    display: block;
    margin: 4.5vw 5vw;
    font-size: 8.5vmin;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
  }
`


const SocialLink = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5vw;
  color: ${colors.black};
  font-weight: 500;
  color: ${colors.royalBlue};
  
  a,
  .login {
    color: inherit;
    display: block;
    margin: 4.5vw 5vw;
    font-size: 6.5vmin;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
  }
`
const Social = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px 6vw;
`

const SocialItem = styled.div`
  margin: 15px 7.5px;
`

const MobileDrawer = ({
  open,
  paddingTop,
  links,
  handleAccount,
  handleDrawer,
}) => {
  const { user } = useUserContext()
  const closeIfActive = (e) => {
    if (e.currentTarget.className.includes("active")) {
      e.preventDefault()
      handleDrawer(!open)
    }
  }

  return (
    <MobileDraweContainer visible={open} pt={paddingTop || 0}>
      <Content>
        <Links>
          {links && links.map((link, index) => {
            const { url, label, preLabel } = link
            return (
              <SingleLink key={index}>
                {preLabel ? preLabel : ""}
                {url.includes("https://www.eatigc.com") ? (
              <>
                { label === "Order" ? 
                <InternalLinkButton to={"/shop"}>
                    Order
                  </InternalLinkButton>
                :  <Link
                    to={url.substring(22)}
                    activeClassName="active"
                    onClick={(e) => closeIfActive(e)}
                  >
                    <p>{label}</p>
                  </Link> 
                  }  
                 
                </>
                
                ) : (
                  <ELink href={url} onClick={() => handleDrawer(!open)}>
                    <p>{label}</p>
                  </ELink>
                )}
              </SingleLink>
            )
          })}
          {/* <SingleLink>
            {user ? <Link to="/account/orders" >
              Account
            </Link> : <Link to="/account/login" >
              Login
            </Link>}
          </SingleLink> */}
        </Links>
        <hr style={{color: colors.royalBlue, marginLeft: "14px", marginRight: "14px"}}/>
            <SocialLink>
            {user ? <Link to="/account/orders" >
              Account
            </Link> : <Link to="/account/login" >
              Login
            </Link>}
          </SocialLink>
              <SocialLink>
             <Link to="/contact" >
              Contact us
            </Link>
            </SocialLink>
        <Social>
            {/* <SingleLink>
            {user ? <Link to="/account/orders" >
              Account
            </Link> : <Link to="/account/login" >
              Login
            </Link>}
          </SingleLink> */}
          <SocialItem>
            <InstagramLink style={{marginLeft: "15px"}} fill={colors.black} width="22" height="22" />
          
          </SocialItem>
        </Social>
      </Content>
    </MobileDraweContainer>
  )
}

export default MobileDrawer
