import CryptoJS from 'crypto-js';

function hashEmail(email) {
  const hash = CryptoJS.SHA256(email.trim().toLowerCase()).toString(CryptoJS.enc.Hex);
  return hash;
}

function hashName(name) {
  // Normalize the name by trimming whitespace and converting it to lowercase
  const normalized_name = name.trim().toLowerCase();

  // Create a SHA-256 hash of the normalized name
  const hash = CryptoJS.SHA256(normalized_name).toString(CryptoJS.enc.Hex);

  return hash;
}

function hashPhoneNumber(phoneNumber) {
  // Normalize the phone number by removing non-numeric characters
  const normalizedPhoneNumber = phoneNumber.replace(/\D+/g, "");

  // Create a SHA-256 hash of the normalized phone number
  const hash = CryptoJS.SHA256(normalizedPhoneNumber).toString(CryptoJS.enc.Hex);

  return hash;
}

export { hashEmail, hashName, hashPhoneNumber };