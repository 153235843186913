const storage = require("../core/localStorage")
const cartProvider = require("../swell/cart")

const LOCAL_STORAGE_CART_KEY = "cart"

const getCart = () => {
  const cartData = storage.getItem(LOCAL_STORAGE_CART_KEY)

  if (cartData) {
    return JSON.parse(cartData)
  }

  return {}
}

const applyCoupon = async (couponCode) => {
  let cart = await cartProvider.applyCoupon(couponCode)
} 

const updateCart = async (promoCode) => {
  let cart = await cartProvider.getCart()
  if (promoCode && cart)
  {
    console.log('promoCode', promoCode)
    try{

      cart = await cartProvider.applyCoupon(promoCode)
    } catch (e)
    {
      
      console.error('couldnt apply coupon' + JSON.stringify(e))
    }
  }
  if (cart) {
    cart = JSON.stringify(cart)
  }
 

  storage.setItem(LOCAL_STORAGE_CART_KEY, cart)
}

const addItem = async (productId, quantity, options, sku, price ) => {
  await cartProvider.addItem(productId, quantity, options)
  let cart = await cartProvider.getCart(productId, quantity, options)

  if (cart) {
    cart = JSON.stringify(cart)
  }

  storage.setItem(LOCAL_STORAGE_CART_KEY, cart)
}

const removeItem = async (productId) => {
  await cartProvider.removeItem(productId)
  let cart = await cartProvider.getCart()

  if (cart) {
    cart = JSON.stringify(cart)
  }

  storage.setItem(LOCAL_STORAGE_CART_KEY, cart)
}

const updateItem = async (productId, quantity) => {
  await cartProvider.updateItem(productId, quantity)
  let cart = await cartProvider.getCart()

  if (cart) {
    cart = JSON.stringify(cart)
  }

  storage.setItem(LOCAL_STORAGE_CART_KEY, cart)
}

const updateAllItems = async (newItems) => {
  const updatedCart = await cartProvider.updateAllItems(newItems)
  
  let cart = await cartProvider.getCart()

  if (cart) {
    cart = JSON.stringify(cart)
  }

  storage.setItem(LOCAL_STORAGE_CART_KEY, cart)
  return updatedCart
}


const updateCartData = async (data) => {
  await cartProvider.updateCartData(data)

  let cart = await cartProvider.getCart()

  if (cart) {
    cart = JSON.stringify(cart)
  }

  storage.setItem(LOCAL_STORAGE_CART_KEY, cart)
}
 
const submitOrder = async () => {
  return await cartProvider.submitOrder()
}

module.exports = {
  applyCoupon: applyCoupon,
  addItem: addItem,
  getCart: getCart,
  updateCart: updateCart,
  removeItem: removeItem,
  updateItem: updateItem,
  updateAllItems: updateAllItems,
  updateCartData: updateCartData,
  submitOrder: submitOrder
}
