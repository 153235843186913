
import { useState, useEffect } from 'react';

export const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const params = {};

      urlParams.forEach((value, key) => {
        params[key] = value;
      });

      setQueryParams(params);
    }
  }, []);

  return queryParams;
};