import React, { useState, createContext } from "react"
import { getCart } from "../services/cart/cart"

export const CartContext = createContext(getCart())

export const CartContextProvider = (props) => {
  const [cart, setCart] = useState(getCart())

  return (
    <CartContext.Provider value={[cart, setCart]}>
      {props.children}
    </CartContext.Provider>
  )
}
